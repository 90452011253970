<template>
    <div class="bg-white">
        <div class="layout-topbar flex w-full col-12">
            <router-link to="/" class="p-link logo layout-topbar-logo">
                <img id="topbar-logo" src="layout/images/logo-white.png" alt="duon-logo" />
            </router-link>
        </div>

        <div>
            <Button @click="backToReport" class="p-button-outlined mt-2 ml-4" label="Retornar ao Relatório" />
        </div>
        <Divider />
        <div class="p-4">
            <div class="card flex flex-column surface-100 p-1">
                <h2> {{ title }} </h2>
                <span class="subtitle" v-if="setor"> {{ setor }} </span>
                <span class="subtitle" v-if="unidade"> {{ unidade }} </span>
                <span class="subtitle" v-if="grupo && risco"> <b> {{ grupo }} > {{ risco }} </b> </span>
                <span class="subtitle" v-if="medidaControle"> {{ medidaControle }} </span>
                <span class="subtitle" v-if="hierarquia"> {{ hierarquia }} </span>
            </div>

            <div class="flex justify-content-center">
                <Galleria v-model:activeIndex="activeIndex" v-model:visible="displayCustom" :value="images"
                    :numVisible="5" :responsiveOptions="responsiveOptions" containerStyle="width: 960px"
                    :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false">
                    <template #item="slotProps">
                        <img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt"
                            style="max-width: 100%; max-height: 540px; object-fit: contain;" />
                    </template>
                </Galleria>

                <div v-if="images" class="flex flex-row flex-wrap justify-content-center">
                    <div v-for="(image, index) of images" :key="index" class="p-2">
                        <img :src="image.thumbnailImageSrc" :alt="image.alt"
                            style="cursor: pointer; width: 351px; height: 170px; object-fit: cover; max-width: 100%; max-height: 100%;"
                            @click="imageClick(index)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { getClientBase } from '../../../../services/http';
import { GrupoRiscosLabelEnum } from '../../../../enums/GrupoRiscos';
export default {
    data() {
        return {
            images: [],
            activeIndex: 0,
            displayCustom: false,
            records: [],
            title: null,
            unidade: null,
            setor: null,
            risco: null,
            grupo: null,
            medidaControle: null,
            hierarquia: null
        };
    },
    mounted() {
        this.setParams();
        this.load();
    },
    methods: {
        async setParams() {
            switch (this.$route.params.origem) {
                case 'CHECKLIST_INICIAL': {
                    const response = await getClientBase().get(`/aplicacao-questionario-inicial/` + this.$route.params.origemId);
                    this.title = 'Anexos do checklist inicial';
                    this.unidade = 'Unidade: ' + response.data?.unidade?.name;
                }
                    break;
                case 'CHECKLIST_AMBIENTE': {
                    const response = await getClientBase().get(`/aplicacao-questionario-ambiente/` + this.$route.params.origemId);
                    this.title = 'Anexos do checklist de ambiente';
                    this.unidade = 'Unidade: ' + response.data?.unidade?.name;
                    this.setor = 'Setor: ' + response.data?.setor?.name;
                }
                    break;
                case 'CHECKLIST_PERIGO': {
                    const response = await getClientBase().get(`/aplicacao-resposta-perigo/${this.$route.params.origemId}`);
                    this.title = 'Anexos do checklist de perigo';

                    const hierarquias = response.data?.inventarioRisco?.inventarioHierarquias.map(p => {
                        return {
                            setor: p.hierarquia.customerDepartment?.name,
                            cargo: p.hierarquia.customerPosition?.name
                        }
                    });
                    const descHierarquia = hierarquias.reduce((acc, curr) => {
                        acc.push(`[${curr.setor}: ${curr.cargo}]`)
                        return acc;
                    }, []);
                    this.hierarquia = descHierarquia.toString();

                    this.grupo = GrupoRiscosLabelEnum.get(response.data?.risco?.grupoRisco);
                    this.risco = response.data?.risco?.nome;
                }
                    break;
                case 'CHECKLIST_PERIGO_MEDIDA_CONTROLE_EXISTENTE': {
                    const response = await getClientBase().get(`/aplicacao-perigo-medida-controle/` + this.$route.params.origemId);
                    this.title = 'Anexos do checklist de perigo - Medida de controle';

                    const hierarquias = response.data?.inventarioRisco?.inventarioHierarquias.map(p => {
                        return {
                            setor: p.hierarquia.customerDepartment?.name,
                            cargo: p.hierarquia.customerPosition?.name
                        }
                    });
                    const descHierarquia = hierarquias.reduce((acc, curr) => {
                        acc.push(`[${curr.setor}: ${curr.cargo}]`)
                        return acc;
                    }, []);
                    this.hierarquia = descHierarquia.toString();

                    this.grupo = GrupoRiscosLabelEnum.get(response.data?.aplicacaoRespostaPerigo?.risco?.grupoRisco);
                    this.risco = response.data?.aplicacaoRespostaPerigo?.risco?.nome;
                    this.medidaControle = response.data?.descricao;
                }
                    break;
                default:
                    break;
            }
        },
        backToReport() {
            window.history.back();
        },
        imageClick(index) {
            this.activeIndex = index;
            this.displayCustom = true;
        },
        async load() {
            const { origem, origemId } = this.$route.params;
            const response = await getClientBase().get(`/anexo/${origem}/` + origemId);
            response.data.map((item) => {
                axios({
                    url: item.url,
                    method: 'GET',
                    responseType: 'blob'
                }).then((res) => {
                    const reader = new FileReader();

                    reader.onload = () => {
                        this.images.push({
                            itemImageSrc: `${reader.result}`,
                            thumbnailImageSrc: `${reader.result}`,
                            alt: 'Description for Image 1',
                            title: 'Title 1'
                        });
                    };

                    reader.readAsDataURL(res.data);
                });

            });
        }
    }
}
</script>

<style>
.layout-topbar {
    background-color: #EF2595;
}

.layout-topbar-logo {
    display: inline-block;
    vertical-align: middle;
}

.logo {
    margin-top: auto;
    margin-bottom: auto;
}

.subtitle {
    text-align: left;
    color: grey;
    line-height: 20px;
}
</style>
